// import VueAnalytics from 'vue-analytics';
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import "./axios";
import store from "./vuex";
import VueSweetalert2 from "vue-sweetalert2";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faHeart as SolidHeart } from "@fortawesome/free-solid-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as SolidBookmark } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import {
	faWhatsapp,
	faFacebook,
	faTwitter,
	faLinkedin,
	faReadme,
} from "@fortawesome/free-brands-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Notifications from "vue-notification";

import "./assets/css/style.css";
import "./assets/css/feather.css";
import "./assets/css/lightbox.css";
import "./assets/css/themify-icons.css";
import "./assets/js/scripts.js";
import "sweetalert2/dist/sweetalert2.min.css";
import VueObserveVisibility from "vue-observe-visibility";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import "../src/plugins/analytics.js";

// Import Bootstrap's CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Import Bootstrap's JavaScript
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "animate.css";

Vue.use(VueSweetalert2);
Vue.use(VueObserveVisibility);
Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(
	faCircleCheck,
	faCircleXmark,
	faEllipsis,
	faCircle,
	faHeart,
	SolidHeart,
	faShareNodes,
	faWhatsapp,
	faFacebook,
	faTwitter,
	faLinkedin,
	faReadme,
	faMagnifyingGlass,
	faComment,
	faPaperPlane,
	faBookmark,
	faArrowRight,
	SolidBookmark,
	faXmark
);

Vue.use(Notifications);

new Vue({
	router,
	store,

	render: (h) => h(App),
})

	// .component('fa',FontAwesomeIcon)x
	.$mount("#app");
