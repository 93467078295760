import Vue from "vue";

import Router from "vue-router";

// Restaurant

import Home from "./components/Home.vue";
// import PageNotFound from "./components/PageNotFound.vue";

import Register from "./components/Register.vue";

import MailVerify from "./components/Auth/MailVerify.vue";
import UserStore from "./components/Auth/UserStore.vue";
import Login_modify from "./components/Auth/Login_modify.vue";
import PasswordReset from "./components/Auth/PasswordReset.vue";

//Register
import RegisterNew from "./components/Auth/RegisterNew.vue";
import OtpVery from "./components/Auth/OtpVery.vue";
import EliteUser from "./components/Auth/EliteUser.vue";
// import SuccessElitePage from "./components/Auth/SuccessElitePage.vue";

import Forgot from "./components/Auth/Forgot.vue";
import Posts from "./components/Posts.vue";
import PostsShow from "./components/PostsShow.vue";

import Wallet from "./components/Wallet.vue";

import LikeStore from "./components/LikeStore.vue";
import Comment from "./components/Comment.vue";
import Country from "./components/Country.vue";

// Restaurant Profile
import Listings from "./components/RestaurantProfile/Listings.vue";
import Profile from "./components/RestaurantProfile/Profile.vue";
import PostReq from "./components/RestaurantProfile/PostReq.vue";
import EditProfile from "./components/RestaurantProfile/EditProfile.vue";
import CreateJob from "./components/RestaurantProfile/CreateJob.vue";
import ViewEnquiry from "./components/RestaurantProfile/ViewEnquiry.vue";
import ShowRequirment from "./components/RestaurantProfile/ShowRequirment.vue";
import RecievedQuote from "./components/RestaurantProfile/RecievedQuote.vue";
import AllVendors from "./components/RestaurantProfile/AllVendors.vue";
import RecievedApp from "./components/RestaurantProfile/RecievedApp.vue";
import Allmanpower from "./components/RestaurantProfile/Allmanpower.vue";
import Franchisee from "./components/RestaurantProfile/Franchisee.vue";
import Sellrest from "./components/RestaurantProfile/Sellrest.vue";
import SellrestEdit from "./components/RestaurantProfile/SellrestEdit.vue";
import SmrEdit from "./components/RestaurantProfile/SmrEdit.vue";
import DownloadInvoice from "./components/RestaurantProfile/DownloadInvoice.vue";
import blue_tick from "./components/RestaurantProfile/blue-tick.vue";

// SMR

import SMRShow from "./components/SMR/SMRShow.vue";

// Franchisee

import FranchiseeShow from "./components/Franchisee/FranchiseeShow.vue";

// Manpower

import ManpowerListings from "./components/Manpower/ManpowerListings.vue";
import ManpowerEditProfile from "./components/Manpower/ManpowerEditProfile.vue";
import MyAppliedJobs from "./components/Manpower/MyAppliedJobs.vue";
import JobsList from "./components/Manpower/JobsList.vue";
import ManpowerShow from "./components/Manpower/ManpowerShow.vue";
import DownloadManpowerInvoice from "./components/Manpower/DownloadManpowerInvoice.vue";
import man_power_bluetick from "./components/Manpower/blue-tick.vue";

// Vendor
import VendorListing from "./components/Vendor/VendorListing.vue";
import VendorEditProfile from "./components/Vendor/VendorEditProfile.vue";
import Enquiries from "./components/Vendor/Enquiries.vue";
import requirements from "./components/Vendor/Requirements.vue";
import VendorShow from "./components/Vendor/VendorShow.vue";
import DownloadVendorInvoice from "./components/Vendor/DownloadVendorInvoice.vue";
// Suscribe

import Search from "./components/Subscribe/Search.vue";
import Fail from "./components/Subscribe/Fail.vue";
import Success from "./components/Subscribe/Success.vue";
import Bluetick_Success from "./components/Subscribe/bluetick_success.vue";

//Header
import Help from "./components/Header/Help.vue";
import Privecy from "./components/Header/Privecy.vue";
import Setting from "./components/Header/Setting.vue";

// atmnirbhar
import Atmnirbhar from "./components/Atmnirbhar/Atmnirbhar.vue";
import AtmPaySuc from "./components/Atmnirbhar/AtmPaySuc.vue";
// Post feed

import PostFeed from "./components/Feed/PostFeed.vue";

// RestaurantAll

import RestaurantAll from "./components/Restaurant/RestaurantAll.vue";
import RestaurantList from "./components/Restaurant/RestaurantList.vue";

import MyPost from "./components/RestaurantProfile/MyPost.vue";

import CommentShow from "./components/RestaurantProfile/CommentShow.vue";

// Landing

import User from "./components/Landing/User.vue";
import PostsSingle from "./components/Landing/PostsSingle.vue";
import ReadMore from "./components/Landing/ReadMore.vue";
import NotAuthPostsingle from "./components/Landing/NotAuthPostsingle.vue";
// import LandingHome from "./components/Landing/LandingHome.vue";

// Followers

import Followers from "./components/Following/Followers.vue";
import Following from "./components/Following/Following.vue";
import Request from "./components/Following/Request.vue";
import Follow from "./components/Following/Follow.vue";
import DeletePolicy from "./components/DeletePolicy.vue";
import privacypolicy from "./components/privacypolicy.vue";

//about us
import about_us from "./components/AboutUs/about_us.vue";

//Dummy User start here
import DummySignin from "@/components/Dummy-User/DummySignin.vue";

// IB Talks and Blogs Components
import IB_Talks_Page from "./components/IB_Talks_And_Blogs/IB_Talks_Page.vue";
import All_Blogs from "./components/IB_Talks_And_Blogs/All_Blogs.vue";
import Blogs from "./components/IB_Talks_And_Blogs/Blogs.vue";

// Tutorial Page Component
import TutorialPage from "./components/TutorialPage/TutorialPage.vue";

// Dummy Tutorial Page
import DummyTutorialPage from "@/components/Dummy-User/DummyTutorialPage.vue";
import DummyIB_Talks_Page from "@/components/Dummy-User/DummyIB_Talks_Page.vue";
import DummyAll_Blogs from "@/components/Dummy-User/DummyAll_Blogs.vue";
import DummyBlogs from "@/components/Dummy-User/DummyBlogs.vue";
import DummyThe_Phoenix_Tale from "@/components/Dummy-User/DummyThe_Phoenix_Tale.vue";

// import SingupPopUp from "./components/Dummy-User/SingupPopUp.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		// {
		// 	path: "/newPopup",
		// 	component: SingupPopUp,
		// 	name: "SingupPopUp",
		// },
		// Dummy IB Talks and Blogs routes
		{
			path: "/dummyIBTalks",
			component: DummyIB_Talks_Page,
			name: "DummyIB_Talks_Page",
		},
		{
			path: "/dummyAllBlogs",
			component: DummyAll_Blogs,
			name: "DummyAll_Blogs",
		},
		{
			path: "/dummyblogs/:id",
			component: DummyBlogs,
			name: "DummyBlogs",
		},
		// Dummy Login Tutorial Page routes
		{
			path: "/dummyTutorialPage",
			component: DummyTutorialPage,
			name: "DummyTutorialPage",
		},
		// Tutorial Page routes
		{
			path: "/tutorialPage",
			component: TutorialPage,
			name: "TutorialPage",
		},
		// IB Talks and Blogs routes
		{
			path: "/IBTalks",
			component: IB_Talks_Page,
			name: "IBTalks",
		},
		{
			path: "/the-phoenix-tale",
			component: DummyThe_Phoenix_Tale,
			name: "DummyThe_Phoenix_Tale",
		},
		{
			path: "/AllBlogs",
			component: All_Blogs,
			name: "AllBlogs",
		},
		{
			path: "/blogs/:id",
			component: Blogs,
			name: "Blogs",
		},
		//Dummy-User pages herr
		{
			path: "/",
			component: DummySignin,
			name: "DummySignin",
		},
		{
			path: "/login",
			component: Login_modify,

			children: [
				{
					name: "OtpVery",
					path: "/OtpVery",
					component: OtpVery,
				},
			],
		},
		{ path: "/reset-password", component: PasswordReset },
		// { path: "*", component: PageNotFound },
		{
			path: "/postsnotAuth/:id",
			component: NotAuthPostsingle,
		},
		{
			path: "/Home",
			component: Home,
			redirect: "/Home/User",
			children: [
				{
					path: "/Home/User",
					component: User,
				},
				{ path: "/about_us", component: about_us },

				{
					path: "/Profile/:id",
					component: Profile,
					name: "Profile",
					children: [
						{
							name: "Following",
							path: "/Profile/:id/Following",
							component: Following,
						},
						{
							name: "Request",
							path: "/Profile/:id/Request",
							component: Request,
						},
						{
							name: "Follow",
							path: "/Profile/:id/Follow",
							component: Follow,
						},
					],
				},

				{
					path: "/Home/User/Atmnirbhar",
					component: Atmnirbhar,
				},

				{
					path: "/Home/User/Fail",
					component: Fail,
				},
				{
					path: "/Home/User/PaymentSuccess",
					component: Success,
				},

				{
					path: "/blueticksuccess",
					component: Bluetick_Success,
				},

				{
					path: "/Home/User/AtmPaySuc",
					component: AtmPaySuc,
				},

				{
					path: "/Home/User/help",
					component: Help,
				},
				{
					path: "/Home/User/privecy",
					component: Privecy,
				},
				{
					path: "/Home/User/setting",
					component: Setting,
				},

				{
					path: "/posts/:id",
					component: PostsSingle,
					beforeEnter: (to, from, next) => {
						const token = localStorage.getItem("token");
						const id = to.params.id;

						if (!token) {
							next(`/postsnotAuth/${id}`); // Redirect to "/another-route/:id" if token is not found
						} else {
							next(); // Proceed with the navigation
						}
					},
				},

				{
					path: "/read-more/:user_type_text/:id",
					component: ReadMore,
				},

				{
					path: "/RestaurantList",
					component: RestaurantList,
					children: [
						{
							path: "/VendorShow/:user_type",
							component: VendorShow,
						},
						{
							path: "/ManpowerShow",
							component: ManpowerShow,
						},
						{
							path: "/SMRShow/:user_type",
							component: SMRShow,
						},
						{
							path: "/FranchiseeShow",
							component: FranchiseeShow,
						},
					],
				},
			],
		},

		{
			name: "Followers",
			path: "/Profile/:id/Followers",
			component: Followers,
		},

		{
			path: "/RegisterNew",
			component: Register,
			redirect: "/UserStore",
			children: [
				{
					name: "UserStore",
					path: "/UserStore",
					component: UserStore,
					redirect: "/RegisterNew",
					children: [
						{
							name: "RegisterNew",
							path: "/RegisterNew",
							component: RegisterNew,
						},
						{
							name: "EliteUser",
							path: "/EliteUser",
							component: EliteUser,
						},
					],
				},

				{
					name: "MailVerify",
					path: "/MailVerify",
					component: MailVerify,
				},
			],
		},

		{
			path: "/Register",
			component: Register,
			redirect: "/UserStore",
			children: [
				{
					name: "UserStore",
					path: "/UserStore",
					component: UserStore,
					redirect: "/EliteUser",
					children: [
						{
							name: "RegisterNew",
							path: "/RegisterNew",
							component: RegisterNew,
						},
						{
							name: "EliteUser",
							path: "/EliteUser",
							component: EliteUser,
						},
					],
				},

				{
					name: "MailVerify",
					path: "/MailVerify",
					component: MailVerify,
				},
			],
		},

		{ path: "/Forgot", component: Forgot },
		{ path: "/Posts", component: Posts },
		{ path: "/PostsShow", component: PostsShow },

		{
			name: "MyPost",
			path: "/Profile/:id/MyPost",
			component: MyPost,
		},

		{ path: "/CommentShow", component: CommentShow },
		{ path: "/Wallet", component: Wallet },

		{ path: "/LikeStore", component: LikeStore },
		{ path: "/Comment", component: Comment },
		{ path: "/Country", component: Country },

		{ path: "/Search", component: Search },
		// { path: '/Home/User/Fail', component: Fail },

		{ path: "/PostFeed", component: PostFeed },

		{ path: "/RestaurantAll", component: RestaurantAll },

		{
			path: "/VendorListing",
			component: VendorListing,
			children: [
				{
					name: "VendorEditProfile",
					path: "/VendorListing/VendorEditProfile/:id",
					component: VendorEditProfile,
				},
				{
					path: "/VendorListing/Enquiries",
					component: Enquiries,
				},
				{
					path: "/VendorListing/requirements",
					component: requirements,
				},
				{
					path: "/VendorListing/DownloadVendorInvoice",
					component: DownloadVendorInvoice,
				},
			],
		},
		{
			path: "/ManpowerListings",
			component: ManpowerListings,
			children: [
				{
					name: "ManpowerEditProfile",
					path: "/ManpowerListings/ManpowerEditProfile/:id",
					component: ManpowerEditProfile,
				},
				{
					path: "/ManpowerListings/MyAppliedJobs",
					component: MyAppliedJobs,
				},
				{
					path: "/ManpowerListings/JobsList",
					component: JobsList,
				},
				{
					path: "/ManpowerListings/DownloadInvoiceManpower",
					component: DownloadManpowerInvoice,
				},
				{
					path: "/ManpowerListings/blue-tick",
					component: man_power_bluetick,
				},
			],
		},
		{
			path: "/Listings",
			component: Listings,
			children: [
				{
					name: "EditProfile",
					path: "/EditProfile/:id",
					component: EditProfile,
				},
				{
					path: "/CreateJob",
					component: CreateJob,
				},
				{
					path: "/PostReq",
					component: PostReq,
				},
				{
					path: "/ViewEnquiry",
					component: ViewEnquiry,
				},
				{
					path: "/ShowRequirment",
					component: ShowRequirment,
				},
				{
					path: "/RecievedQuote",
					component: RecievedQuote,
				},
				{
					path: "/AllVendors",
					component: AllVendors,
				},
				{
					path: "/RecievedApp",
					component: RecievedApp,
				},
				{
					path: "/Allmanpower",
					component: Allmanpower,
				},
				{
					path: "/Franchisee",
					component: Franchisee,
				},
				{
					path: "/SellRest",
					component: Sellrest,
				},
				{
					path: "/SellrestEdit",
					component: SellrestEdit,
				},
				{
					path: "/SmrEdit",
					component: SmrEdit,
				},
				{
					path: "/DownloadInvoice",
					component: DownloadInvoice,
				},
				{
					path: "/blue-tick",
					component: blue_tick,
				},
			],
		},
		{
			path: "/deletionPolicy",
			component: DeletePolicy,
		},
		{
			path: "/privacypolicy",
			component: privacypolicy,
		},

		// landing page
		// {
		//   path: "/",
		//   component: LandingHome,
		//   name: "LandingHome",
		// },
	],
});
