<template>
  <div
    class="w-100 d-flex align-items-center justify-content-center"
    style="height: 100vh"
  >
    <!-- Button trigger modal -->
    <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#exampleModalCenter"
      style="display: none"
      ref="pop_up_btn"
    >
      Click Here To Open Pop Up
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      @click="handleClickOutside"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" ref="modal_div">
          <div class="modal-header">
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
              ref="close_btn"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" class="cross-icon" />
            </button>
          </div>
          <div class="modal-body">
            <div class="pop-img-div">
              <img src="./assets/newPopImg.png" alt="image" />
            </div>
            <div class="pop-content-div">
              <p>
                "We need to understand your needs to serve you better. Kindly
                complete <span>sign up process</span>"
              </p>
            </div>
            <button
              type="button"
              class="btn custom-button-class"
              @click="redirect()"
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignupPopUp",
  props: {
    modal_visible: Boolean,
  },
  data() {
    return {
      showModal: this.modal_visible,
      btn: null,
      closeBtn: null,
    };
  },
  methods: {
    redirect() {
      this.closeBtn.click();
      this.$router.push("/RegisterNew");
    },
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.$emit("closeModal");
      }, 500);
    },

    handleClickOutside(event) {
      // console.log("Function Called !!");
      const modalDiv = this.$refs.modal_div;
      // const modalContent = document.querySelector(".modal-content");
      if (!modalDiv.contains(event.target)) {
        // console.log("Inside IF Condition !!!!");
        this.closeBtn.click();
      }
    },
  },
  mounted() {
    this.btn = this.$refs.pop_up_btn;
    this.closeBtn = this.$refs.close_btn;

    if (this.modal_visible) {
      this.btn.click();
    }
  },
};
</script>

<style scoped>
.modal-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cross-icon {
  font-size: 1.5rem;
}

.modal-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pop-img-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-img-div img {
  width: 14rem;
  margin-top: -20px;
}

.pop-content-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 1rem;
}

.pop-content-div p {
  width: 100%;
  font-size: 1.03rem;
  font-weight: 600;
  color: gray;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0;
}

.pop-content-div span {
  color: red;
}

.custom-button-class {
  width: 70%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 2rem 0;
  border-radius: 10px;
  background-color: #004898;
  color: white;
}

.custom-button-class:hover {
  opacity: 0.7;
  background-color: inherit;
  border: 1px solid #004898;
  color: #004898;
}

@media screen and (max-width: 576px) {
  .pop-img-div img {
    width: 12rem;
  }

  .pop-content-div {
    padding: 0 0;
  }

  .pop-content-div p {
    font-size: 1.1rem;
  }

  .custom-button-class {
    width: 80%;
    font-size: 1.1rem;
  }
}
</style>